

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */

   .calendly-badge-widget {
     display: none !important;
   }
}



@media screen and (min-width: 1000px) {
  .desktopNoWrapWhiteSpace {
    white-space: nowrap !important;
  }
}

.date-filter {
  margin-top: 5px;
}

.date-filter span {
  width: 40px;
  display: inline-block;
  text-align: center;
}

.date-filter input {
  padding: 7px 12px !important;
}

@media screen and (min-width: 768px) and (max-width: 1020px) {
  #search-button {
    margin-bottom: 5px;
  }
}

.cad-qc-dot {
  float: left;
  left: 5px;
  background: red;
  position: absolute;
  top: 5px;
  border-radius: 10px;
  height: 15px;
  width: 15px;
}

#cad-options-dropdown {
  float: right;
  position: relative;
  right: -15px;
  bottom: 35px;
  cursor: pointer;
  margin-bottom: -100px;
}

#cad-options-dropdown .active.selected.item {
  font-weight: normal !important;
}

#cad-options-dropdown .dropdown.icon {
  display: none;
}

#cad-options-dropdown .menu > .item {
  background: white !important;
}

#cad-options-dropdown .menu > .item:hover {
  background: rgba(0,0,0,.05)!important;
}

#export-dropdown .selected.item {
  background: white !important;
}

#export-dropdown .selected.item:hover {
  background: rgba(0,0,0,.05)!important;
}

.ui.checkbox input.hidden+label {
  user-select: auto !important;
}







/* rheostat start */
.DefaultProgressBar__vertical {
  width: 24px;
  height: 100%
}
.DefaultProgressBar_progressBar {
  background-color: #abc4e8;
  position: absolute
}
.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: 24px
}
.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: 15px
}
.DefaultProgressBar_background__horizontal {
  height: 13px;
  top: 0px
}
.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  background-color: #fcfcfc;
  border-radius: 20%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #dbdbdb
}
.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px
}
.DefaultHandle_handle:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8
}
.DefaultHandle_handle:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8
}
.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -5px
}
.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 10px
}
.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 13px
}
.DefaultHandle_handle__vertical {
  margin-top: -12px;
  left: -10px
}
.DefaultHandle_handle__vertical:before {
  top: 10px
}
.DefaultHandle_handle__vertical:after {
  top: 13px;
  left: 8px;
  height: 1px;
  width: 10px
}
.DefaultHandle_handle__disabled {
  border-color: #dbdbdb
}
.DefaultBackground {
  background-color: #fcfcfc;
  height: 15px;
  width: 100%;
  border: 1px solid #d8d8d8;
  position: relative
}
.DefaultBackground_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}
.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
}
.rheostat {
  position: relative;
  overflow: visible
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px
  }
}
.rheostat__vertical {
  height: 100%
}
.handleContainer {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute
}
.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative
}
.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}
.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
}


.rheostat button {
  z-index: 1 !important;
}
/* rheostat end */

/* custom chart */

.canvasjs-chart-credit {
  display: none !important;
}





/* don't show the note in the show hide modal */

#show-hide-note-holder .show-hide-note,  #show-hide-note-holder .hideable-input-index {
  display: none;
}

#multi-search-message .header {
  padding-left: 30px;
}


@media screen and (min-width: 768px) {
  .blue-action-button {
    position: absolute;
  }

  #inline-loader {
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

@media screen and (max-width: 767px) {
  #eye-action-button {
    margin-bottom: 8px;
  }
  #number-of-styles-ui-label, #future-ats-ui-label {
    margin-bottom: 8px;
  }
  #default-account-dropdown {
    width: 100% !important;
  }
}

.recent-search-link {
  color: blue;
  cursor: pointer;
}

.recent-search-link:hover {
  text-decoration: underline;
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  #search-po-number {
    position: absolute;
    top: -24px;
  }
}

.date-filter > .field {
  display: inline-block;
}

.white-space-nowrap-desktop {
  white-space: nowrap;
}

.hideable-input-index > input {
  padding: 0 !important;
  text-align: center !important;
  pointer-events: none;
  display: none;
}

#my-fullscreen-cad-viewer > img {
  border: 1px solid rgb(225,225,225);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px -2px;
  padding: 20px;
}

.cads-reset-filters {
  cursor: pointer;
  color: rgb(145,145,145) !important;
  font-size: 115%;
}

.cads-reset-filters:hover {
  text-decoration: underline;
}


#cads-search-input > .input {
  width: 100%;
}

#cads-search-type-input > .input {
  width: 100%;
  max-width: 150px;
}
@media screen and (max-width: 767px) {
  #cads-search-input {
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  #cads-search-input {
    width: 32%;
    float: right;
    position: relative;
    top: 17px;
  }

  #cads-search-type-input {
    width: 10%;
    float: right;
    position: relative;
    top: 7px;
    margin-left: 20px;
  }
}

.cads-loading-box {
  display: inline-block;
  border: 1px solid rgb(222,222,222);
  padding: 0px 20px;
  margin-bottom: 20px;
  border-radius: .28571429rem;
}

.cads-loading-box > ul {
  display: inline-block;
}

@media screen and (min-width: 768px) {
  #cads-default-contact-view {
    width: 17%;
    float: right;
    margin-right: 25px;
  }

  #cads-page-grid-second-row, #cads-page-grid-third-row {
    padding-top: 0;
    margin-top: -5px;
  }
}

#my-fullscreen-cad-viewer {
  text-align: center;
  background: white;
  position: fixed;
  width: 100%;
  top: 0;
  height: 100vh;
  z-index: 9999;
  left: 0;
}

#zoomingInfoBoxStyle {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.15);
  background: white;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 999999;
  display: block;
  text-align: left;
  white-space: nowrap;
  border: 1px solid rgb(225,225,225);
}

.multiSearchButton {
  padding: 2px 5px 2px 10px !important;
  border-radius: 10px !important;
  float: right;
  margin-top: 5px !important;
}

#recentSearches-holder {
  float: right;
  margin-right: 17% !important;
}

#multi-search-textarea {
  width: 100%;
  min-width: 100%;
  height: 100px;
  max-width: 100%;
  max-height: 300px;
}

@media screen and (min-width: 768px) {
  #multi-search-textarea-holder {
    float: right;
    width: 35%;
    max-width: 420px;

  }
}

.cads-visible-fields-button > button {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 4px !important;
  float: right;
  position: relative;
  bottom: 40px;
  right: 45px;
}

.cads-visible-fields-button > button:hover > i {
  color: rgb(73,137,215) !important;
}

.locked-section, .locked-section #customer-order-number, .locked-section > label {
  cursor: not-allowed !important;
}

.locked-section > label {
  opacity: 0.4;
}

@media screen and (min-width: 768px) {
  .percent80-width-desktop {
    width: 80%;
  }
  .percent50-width-desktop {
    width: 50%;
  }
}
