
.ui.card > .image > img {
  max-width: 100%;
  vertical-align: middle;
  cursor: -webkit-zoom-in;
  max-height: 200px !important;
  width: auto !important;
  margin: auto !important;
  display: inline-block !important;
  border-radius: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: block !important;
}

.ui.card > .image {
  background: white !important;
}


@media screen and (max-width: 767px) {
  .ui.card > img {
    margin: auto !important;

  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */
   .ui.card > .image > img {
      /* margin: 10px auto !important; */
      width: 200px !important;
   }
}

.extra.content, .ui.card > .content > .meta  {
  font-size: 12px !important;
}

.ui.card .header > .ui.checkbox {
  font-size: 1em;
}

.ui.card { /* :first-of-type */
  border: 1px solid rgb(242,242,242) !important;
}


/* hard to see 3 dots on cad card */
.ellipsis.horizontal.icon {
  text-shadow: 0px 0px 3px white;
}

.ellipsis.horizontal.icon:hover {
  opacity: 0.5
}
