.prints-link {
  background: white;
  color: #757575;
  position: relative;
  bottom: 2px;
}

.prints-link:hover {
  font-weight: bold;
  color: black;
}
