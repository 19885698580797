.shipped-cancel-tabs .trash {
  color: red;
}

.shipped-cancel-tabs .ship {
  /* color: rgb(63,133,202); blue*/
  color: rgb(227,120,55);
}

.shipped-cancel-tabs .clone {
  color: rgb(244,196,85);
}
