.contact-checkbox {
  cursor: pointer;
  zoom: 150%;
  position: relative;
  top: 2px;
}

#contactStatusSelect {
  float: right;
  width: 130px;
  zoom: 120%;
  padding: 5px;
  border-radius:5px;
  border: 1px solid rgb(222,222,222);
  cursor: pointer;
}

#contactStatusSelect:disabled {
  cursor: not-allowed;
}

#contactStatusSelect:hover {
  border: 1px solid rgb(178,178,178);
}

#bulkAddNote {
  float: right;
  margin-left: 10px;
}

#bulkAddNote.myDisabled {
  cursor: not-allowed;
  color: rgb(166,166,166) !important;
}

#showFilters {
  float: right;
  margin-right: 10px;
}

@media (min-width:1200px) {
  #contactsSearch {
    position: fixed;
    top: 15px;
    z-index: 1000;
    right: 200px;
  }
}

@media (max-width:1199px) {
  #contactsSearch {
    float: right;
    margin-right: 10px;
  }
}

#addContactNote {
  width: 100%;
  padding: 10px;
}

#fileDropzone {
  border: 1px dashed rgb(69,160,197) !important;
  background: rgb(250,250,250) !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

#fileDropzone:hover {
  border: 1px dashed rgb(109,200,237);
}

#fileDropzone > p {
  text-align: center;
  padding: 50px;
}

.added-files {
  text-align: center;
  padding: 50px;
  background: rgb(250,250,250);
  border: 1px solid rgb(240,240,240);
  margin-top: 25px;
}

.remove-file-button {
  padding: 0;
  padding: 0px 5px;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 6px;
}

.no-notes {
  margin-top: 50px;
  padding-bottom: 50px;
  display: block;
}

#filter-section {
  background: #fafafa;
  margin-bottom: 25px;
  padding: 13px 20px;
  padding-bottom: 0;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
}

.ui.sortable.table thead th {
  border-left: 0px !important;
}
