.simple-table-holder {
  overflow-x: scroll;
  padding-bottom: 200px;
}

.simple-table-holder::-webkit-scrollbar {
  display: none;
}

.simple-table-image-row {
  width: 100px;
  white-space: nowrap;
}

.simple-table-image-row > div {
  width: 85vw;
  overflow-x: auto;
  margin: 20px;
}

.simple-table-image-row > div > img {
  height: 200px;
  width: auto;
}

.simple-table-first-column {
  position: sticky;
  left: 0px;
  background: rgb(247,247,247) !important;
}

.visible-column-and-refresh-holder {
  z-index: 999;
  position: relative;
  bottom: 50px;
}

@media screen and (max-width: 1550px) {
  .visible-column-and-refresh-holder {
    bottom: 54px;
  }
}
