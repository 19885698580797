#bol-pod-section span.asterik {
	font-size: 1.25rem;
}

#bol-pod-section .sub.header {
	padding-top: 5px;
}

#bol-pod-section .date-picker-col .field,
#bol-pod-section .date-picker-col .input {
	width: 100%;
}

#bol-pod-section .ponumber-input .check {
	opacity: 1;
	color: green;
}

#bol-pod-section .ponumber-input .close {
	color: red;
	opacity: 1;
}
