#add-style-input-holder > div > .check.icon, .customer-po-number-input-holder > div > .check.icon {
  color: green !important;
}

#add-style-input-holder > div > .close.icon, .customer-po-number-input-holder > div > .close.icon {
  color: red !important;
}

#add-style-input-holder {
  text-align: right;
}
