#scroll-to-top, #bulk-edit-cads-corner-button {
  opacity: 0.7;
  border-radius: 17px;
  background: red !important;
  color: white !important;
  font-weight: bold;
}

#scroll-to-top-corner-buttons {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: none;
}

#bulk-edit-cads-corner-button {
  background: rgb(229,111,33) !important;
  bottom: 104px;
  position: fixed;
  right: 10px;
  display: none;
}

#best-seller-export-corner-buttons {
  bottom: 51px;
  position: fixed;
  right: 10px;
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

#best-seller-export-corner-buttons > button {
  opacity: 0.7;
  background: white !important;
  border-radius: 17px;
  font-weight: bold;
  background: rgb(104,168,235) !important;
  color: white !important;
}

#best-seller-export-corner-buttons > button:hover, #scroll-to-top:hover, #bulk-edit-cads-corner-button:hover {
  opacity: 1;
}

#best-seller-export-corner-buttons > .best-seller-corner-button > i {
  margin-right: -3px;
}
