html {
	scroll-padding-top: 100px !important;
}

.defaultPadding {
	padding: 20px 40px;
}

body, .ui.menu .item, h1, h2, h3, h4, h5 {
	font-family: 'Open Sans', sans-serif !important;
}

h1.cursive {
	font-family: "Dancing Script", cursive !important;
}

.accordion.ui.heavyShadow, table.heavyShadow, .heavyShadow {
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
	border-radius: 5px;
}

a.boldWhenHover:hover {
	font-weight: bold !important;
}

#main-nav {
	-webkit-box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
	width: 100%;
	z-index: 1000;
	position: fixed;
	top: 0;
}

#main-nav .ui.menu {
	box-shadow: none;
	border: none;
}

#main-nav .brand-section {
	position: absolute;
	top: 1px;
	padding: 5px;
	left: 52px;
}

#main-nav .brand-section img {
	width: 50px;
}

#main-nav .item {
	color: #757575;
}

#main-nav .item.active {
	font-weight: bold;
}

#main-nav .item.active,
  #main-nav .item:hover {
	color: #292929 !important;
}

#main-nav .item.dropdown i {
	display: none;
	margin: 0;
}

#main-nav .item:before {
	width: 0px;
}

#main-nav .hr-menu > i {
	position: absolute;
	right: 60px;
	display: inherit !important;
}

#main-nav .user-content:hover {
	color: #757575 !important;
	font-weight: 400;
}

#main-nav .menu.visible {
	z-index: 1001;
}

#app-wrapper {
	margin-top: 70px;
}

/* file uploader */
#po-files-dropzone {
	border: 10px dashed #ccc;
	height: calc(100vh - 190px);
	padding-top: calc(48vh - 100px);
	text-align: center;
}

#po-files-dropzone:hover {
	border: 10px dashed #0c0;
}

.react-tel-input input.form-control {
	padding-left: 45px !important;
}

.ui.popup.error {
	background: #9f3a38 !important;
}

.ui.popup.error .content {
	background: #9f3a38 !important;
	color: #fffff !important;
}

.ui.popup.error:before {
	background: #9f3a38 !important;
}

.ui.form .required.field > .checkbox:after, .ui.form .required.field > label:after, .ui.form .required.fields.grouped > label:after, .ui.form .required.fields:not(.grouped) > .field > .checkbox:after, .ui.form .required.fields:not(.grouped) > .field > label:after {
	margin: 0;
	content: '';
	font-size: 10px;
	color: none;
}

.field-disabled {
	cursor: not-allowed;
}

.field-disabled input {
	pointer-events: none;
	opacity: .45;
}

label span.optional {
	color: grey;
	font-weight: normal;
}

.link {
	color: #4183c4;
	cursor: pointer;
}

.field.ui-date-picker {
	width: 100%;
}

.field.ui-date-picker .react-datepicker-wrapper {
	width: 100%;
}

.field.ui-date-picker .react-datepicker-wrapper .react-datepicker__input-container {
	width: 100%;
}

.field.ui-date-picker .react-datepicker-wrapper .react-datepicker__input-container button.date-input-custom {
	padding-left: 15px;
	width: 100%;
	text-align: left;
}

.field.ui-date-picker .react-datepicker-wrapper .react-datepicker__input-container button.date-input-custom.error {
	color: #e0b4b4 !important;
	background-color: #fff6f6 !important;
	-webkit-box-shadow: 0 0 0 1px #e0b4b4 inset !important;
	box-shadow: 0 0 0 1px #e0b4b4 inset !important;
}

.field.ui-date-picker .react-datepicker-wrapper .react-datepicker__input-container button.date-input-custom .placeholder {
	opacity: 0.7;
}

.dis * {
	opacity: .45;
	pointer-events: none;
	cursor: col-resize !important;
}

.text-brown {
	color: #CBAF8A;
}

.text-orange {
	color: #ff851b;
}

.text-red {
	color: #9f3a38;
}

.text-underline {
	text-decoration: underline;
}

.ui.segment-wrap {
	padding: 2em;
}

.ui.segment-wrap .submit-btn {
	margin: 20px 0 10px 0;
}

@media screen and (max-width: 767px) {
	#select-all-cads-button {
		margin-top: 8px;
	}
}

@media screen and (max-width: 767px) {
	#welcome-text {
		display: block;
		margin-top: 20px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1130px) {
	#welcome-text {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	#inventory-report {
		display: block;
		margin-right: 0px !important;
		margin-top: -20px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1380px) {
	#inventory-report {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	#sign-out-button {
		margin-left: 0 !important;
		margin-top: 20px;
	}
}

@media screen and (max-width: 767px) {
	#logo-li {
		margin-bottom: 20px;
		margin-right: 80vw !important;
		padding: 0 !important;
	}
}

@media screen and (max-width: 767px) {
	#logo-li img {
		margin-left: 20px !important;
		margin-top: 10px;
		position: inherit !important;
		padding: 0 !important;
		position: relative !important;
		top: 20px !important;
		left: 0 !important;
	}
}

#main-nav > li > a {
	color: #757575;
}

#main-nav > li > a.is-active, #main-nav > li > a:hover {
	color: #292929;
}

#main-nav > li > a.is-active {
	border-bottom: 1px solid #292929;
}

#main-nav {
	-webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1200px) {
	#auth-button-li {
		float: right;
		margin-right: 40px;
	}
}

#auth-button-li p {
	color: #757575;
}

@media screen and (max-width: 767px) {
	#auth-button-li {
		width: 100vw;
	}
}

/* auth0 */
.auth0-lock-header {
	height: 50px !important;
}

.auth0-lock-custom-icon {
	width: 13px;
	height: 13px;
	opacity: 0.5;
	pointer-events: none;
}

/* Calendar */
.calendar {
	display: block;
	position: relative;
	width: 100%;
	background: var(--neutral-color);
	border: 1px solid var(--border-color);
}

.calendar .header {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 115%;
	padding: 1.5em 0;
	border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
	cursor: pointer;
	transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
	transform: scale(1.05);
	transition: 0.25s ease-out;
	color: var(--main-color);
}

.calendar .header .icon:first-of-type {
	margin-left: 1em;
}

.calendar .header .icon:last-of-type {
	margin-right: 1em;
}

.calendar .days {
	text-transform: uppercase;
	font-weight: 400;
	color: var(--text-color-light);
	font-size: 70%;
	padding: 0.75em 0;
	border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
	position: relative;
	height: 7em;
	border-right: 1px solid var(--border-color);
	overflow: hidden;
	cursor: pointer;
	background: var(--neutral-color);
	transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
	background: var(--bg-color);
	transition: 0.5s ease-out;
}

.calendar .body .selected {
	border-left: 10px solid transparent;
	border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
	border-image-slice: 1;
}

.calendar .body .row {
	border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
	border-bottom: none;
}

.calendar .body .cell:last-child {
	border-right: none;
}

.calendar .body .cell .number {
	position: absolute;
	font-size: 82.5%;
	line-height: 1;
	top: 0.75em;
	right: 0.75em;
	font-weight: 700;
}

.calendar .body .disabled {
	color: var(--text-color-light);
	pointer-events: none;
}

.calendar .body .cell .bg {
	font-weight: 700;
	line-height: 1;
	color: var(--main-color);
	opacity: 0;
	font-size: 8em;
	position: absolute;
	top: -0.2em;
	right: -0.05em;
	transition: 0.25s ease-out;
	letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
	opacity: 0.05;
	transition: 0.5s ease-in;
}

.calendar .body .col {
	flex-grow: 0;
	flex-basis: calc(100% / 7);
	width: calc(100% / 7);
	padding-right: 2vw;
	text-align: right;
}

/* GRID */
.row {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.row-middle {
	align-items: center;
}

.col {
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

.col-start {
	justify-content: flex-start;
	text-align: left;
}

.col-center {
	justify-content: center;
	text-align: center;
}

.col-end {
	justify-content: flex-end;
	text-align: right;
}

.underline-on-hover:hover {
	text-decoration: underline;
}

/* fix for edit cad page */
@media screen and (min-width: 768px) {
	.third-width-desktop {
		width: 33.5%;
	}

	.two-third-width-desktop {
		width: 67.5%;
	}

	.width-80-percent-desktop {
		width: 80%;
	}

	.break-word-14vw-desktop {
		overflow-wrap: break-word;
		max-width: 14vw;
	}
}

/* factory page */
/* fix new logo */
.ui.card.missing-image > .image, .ui.cards > .card.missing-image > .image {
	padding: 25px;
	background: transparent;
}

img.missing {
	padding: 25px;
}

/* fix nav */
.ui.basic.button.background-white {
	background: white !important;
}

/* fix export button styling */
#export-dropdown {
	color: rgba(0, 0, 0, 0.6) !important;
}

/*check out page*/
@media screen and (min-width: 768px) {
	.bulk-checkout-holder {
		height: 0;
		width: 0;
	}

	.bulk-checkout {
		position: relative;
		bottom: 100px;
		height: 80px;
		width: 200px;
	}
}

@media screen and (max-width: 767px) {
	.bulk-checkout-holder {
		display: none;
	}
}

.ui.dimmer {
	background-color: rgba(0, 0, 0, 0.75) !important;
}

table > thead > tr > th {
	text-transform: uppercase !important;
}

#data-table button.ui.icon.button {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

.hiddenHTML5DummyInput {
	padding: 0px !important;
	border: 0 !important;
	opacity: 0;
	height: 0;
	pointer-events: none;
	overflow: hidden;
	display: block !important;
	margin-bottom: -10px !important;
	position: relative;
	bottom: 18px;
}

.fabrication-dropdown > .text, .fabrication-dropdown > .menu > .item {
	word-wrap: break-word !important;
	max-width: 100%;
}

#po-nav > a.nav-basic-button {
	background: transparent !important;
	padding: 5px 10px !important;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6) !important;
}

.nav-dropdown > .dropdown {
	padding: 5px 10px !important;
}

.dropdown-nav-item {
	color: black;
}

#main-nav .ui.simple.dropdown > div.menu {
	width: 100% !important;
}

#main-nav .ui.simple.dropdown > div.menu > div.item {
	padding: 0 !important;
}

#main-nav .ui.simple.dropdown > div.menu > div.item > a {
	padding: .78571429rem 1.14285714rem !important;
	display: block;
}

#main-nav .ui.simple.dropdown > div.text {
	cursor: auto !important;
	color: #757575 !important;
}

.dropdown-nav-item.is-active {
	text-decoration: underline !important;
}

.ui.basic.button.method-button-disabled {
	background: lightgrey !important;
}

#sticky-org-btn {
	position: fixed;
	left: 10px;
	bottom: 10px;
}

/*fix view contact page*/
#right-grid > .row > .column {
	width: 100% !important;
}

.float-right {
	float: right;
}

/* UTILS */
.group:after {
	content: "";
	display: block;
	clear: both;
}

/* fix alerts CSS */
#app-wrapper > div > .ui.mini.message {
	margin: 50px !important;
	margin-top: 94px !important;
	margin-bottom: 0px !important;
}

/* dropdown section divider */
.ui.dropdown .menu > .header {
	pointer-events: none !important;
	border-top: 1px solid rgba(34, 36, 38, 0.3) !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	line-height: 1.3em;
	opacity: 0.5;
	font-size: 70% !important;
}

#main-nav-divider {
	font-size: 12px !important;
	font-weight: bold !important;
}

/* (B) SIMPLE BOUNCE */
@keyframes bounce {
  0% { transform: translateY(2px); }
  100% { transform: translateY(-3px); }
}

.bounce {
  animation: bounce 0.3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.spinning-cog {
	display: inline-block !important;
	-webkit-animation-name: spin !important;
	-webkit-animation-duration: 4000ms !important;
	-webkit-animation-iteration-count: infinite !important;
	-webkit-animation-timing-function: linear !important;
	-moz-animation-name: spin !important;
	-moz-animation-duration: 4000ms !important;
	-moz-animation-iteration-count: infinite !important;
	-moz-animation-timing-function: linear !important;
	-ms-animation-name: spin !important;
	-ms-animation-duration: 4000ms !important;
	-ms-animation-iteration-count: infinite !important;
	-ms-animation-timing-function: linear !important;
	animation-name: spin;
	animation-duration: 4000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.margin-left-neg3px {
	margin-left: -3px !important;
}

.padding-left-3px {
	padding-left: 3px !important;
}

.padding-left-5px {
	padding-left: 5px !important;
}

@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

#qb-icon-status {
	width: 16px !important;
	height: 16px !important;
	position: relative;
	bottom: 1px;
}

@media screen and (min-width: 1000px) {
	#cad-details-table {
    width: 92vw;
    position: relative;
    right: calc((95vw - 930px)/2);
	}

	#vpo-order-summary-table {
    width: 80vw;
    position: relative;
    right: calc((80vw - 950px)/2);
	}
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#admin-pin-input {
	text-security: disc;
	-webkit-text-security: disc;
}
