#my-dropzone {
  border: 10px dashed #ccc;
  height: calc(100vh - 290px);
  padding-top: calc(48vh - 140px);
  text-align: center;
}

#my-dropzone:hover {
  border: 10px dashed #0c0;
}
