#my-dropzone {
  border: 10px dashed #ccc;
  height: calc(100vh - 290px);
  padding-top: calc(48vh - 150px);
  text-align: center;
}

#my-dropzone:hover {
  border: 10px dashed #0c0;
}


.ui.active.transition.visible.dimmer {
  position: fixed !important;
}
