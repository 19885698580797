.react-tel-input input {
	width: 100% !important;
}

.drop-zone {
	overflow: hidden;
	transition: height 0.08s;
	height: 250px;
	border: 1px dashed white;
	padding: 10px;
}

.drop-zone .expanded-block {
	text-align: center;
}

.drop-zone .expanded-block .ui-icon {
	margin-top: 25px;
	margin-bottom: 25px;
	width: 100px;
	height: 100px;
}

.drop-zone .expanded-block .ui-icon svg {
	fill: green;
}

.drop-zone .expanded-block div:nth-child(2) {
	margin-bottom: 5px;
	font-size: 14px;
}

.drop-zone .expanded-block div:nth-child(3) {
	font-size: 14px;
	color: grey;
	text-decoration: underline;
}

.drop-zone .collapsed-block {
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 5px;
	display: none;
	justify-content: space-between;
}

.drop-zone .collapsed-block .ui-icon {
	width: 20px;
	height: 20px;
}

.drop-zone .collapsed-block .ui-icon svg {
	fill: green;
}

.drop-zone .collapsed-block div:first-child span:nth-child(2) {
	margin-left: 10px;
	font-size: 14px;
	color: grey;
	text-decoration: underline;
}

.drop-zone .modal {
	display: none;
	height: 100%;
	width: 100%;
}

.drop-zone.accept-files .expanded-block,
  .drop-zone.accept-files .collapsed-block {
	display: none;
}

.drop-zone.accept-files .modal {
	display: block;
	background: green;
}

.drop-zone.reject-files .expanded-block,
  .drop-zone.reject-files .collapsed-block {
	display: none;
}

.drop-zone.reject-files .modal {
	display: block;
	background: red;
}
